import 'twin.macro';
import { ContentSportsEnum } from '@/graphql/gql/graphql';
import Link from '@/components/Link';
import { relativeDate } from '@/utils/date';
import { PLAYER_NEWS_SOURCE_LINK_CLICKED } from '@/constants/amplitude';
import { useAmplitude } from '@/utils/hooks/useAmplitude';

type PlayerNewsPublishProps = {
  sourceName: string,
  sourceLink?: string,
  date?: string,
  sportName: ContentSportsEnum,
};

const PlayerNewsPublish = ({ date, sourceName, sourceLink, sportName }: PlayerNewsPublishProps) => {
  const { logAmplitudeEvent } = useAmplitude();
  return (
    <div tw="text-xs font-normal leading-4">
      {sourceLink ? (
        <Link
          onClick={() => logAmplitudeEvent(PLAYER_NEWS_SOURCE_LINK_CLICKED, {
            'Link Text': sourceName ?? '',
            'Link URL': sourceLink ?? '',
            module: 'featured_news',
            Competition: sportName,
          })}
          next={false}
          href={sourceLink}
          tw="font-normal text-blue-d1"
        >
          {sourceName}
        </Link>
      ) : (
        <span tw="text-grey-d4">{sourceName}</span>
      )}
      <span tw="pl-1">&#8226;</span>
      {date && (
      <time
        data-testid="relative-date"
        dateTime={date}
        tw="pl-1"
        suppressHydrationWarning
      >
        {relativeDate(date)}
      </time>
      )}
    </div>
  );
};

export default PlayerNewsPublish;
