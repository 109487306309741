import tw, { styled } from 'twin.macro';
import Link from 'next/link';
import { Tool } from '@/graphql/gql/graphql';
import East from '@/components/svg/East';
import { useAmplitude } from '@/utils/hooks/useAmplitude';
import { useCallback } from 'react';
import { TOOL_LINK_CLICKED } from '@/constants/amplitude';

const Title = styled.h2(() => [
  tw`text-sm font-bold leading-5 line-clamp-3`,
]);

const ToolContainer = styled.div(({ condensed }: ToolContainerProps) => [
  tw`rounded bg-grey-l4 p-4 text-grey-d4 flex flex-col justify-around`,
  !condensed && tw`xl:h-[130px]`,
]);

type ToolContainerProps = { condensed?: boolean };

type ToolComponentProps = Tool & ToolContainerProps & { origin: string };

const ToolComponent = (
  { description, title, url, condensed, origin, newWindow }: ToolComponentProps,
) => {
  const { logAmplitudeEvent } = useAmplitude();

  const clickEvent = useCallback(() => {
    logAmplitudeEvent(TOOL_LINK_CLICKED, {
      'Link Text': title,
      'Link URL': url,
      Module: origin,
    });
  }, [title, url, logAmplitudeEvent, origin]);

  return (
    <ToolContainer condensed={condensed}>
      <Link target={newWindow ? '_blank' : undefined} onClick={clickEvent} href={url} prefetch={false}>
        <div tw="flex flex-nowrap items-center gap-2">
          <Title>{title}</Title>
          <div tw="min-w-[20px]">
            <East />
          </div>
        </div>
        {description ? <div tw="mt-2 text-xs">{description}</div> : undefined}
      </Link>
    </ToolContainer>
  );
};

export default ToolComponent;
