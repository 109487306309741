import Image from '@/components/Image';
import Styles from './TeamPlayerAvatar.module.scss';

type TeamPlayerAvatarProps = {
  primaryImgName: string,
  primaryImgUrl?: string | null,
  primaryImgSize: number,
  secondaryImgName?: string,
  secondaryImgUrl?: string | null,
  secondaryImgSize: number,
};

const TeamPlayerAvatar = ({
  primaryImgName,
  primaryImgUrl,
  primaryImgSize,
  secondaryImgName,
  secondaryImgUrl,
  secondaryImgSize,
}: TeamPlayerAvatarProps) => (
  <div className={Styles.avatarContainer}>
    <Image
      fallback="/research/images/personPlaceholder.svg"
      className={Styles.avatar}
      style={{
        width: primaryImgSize,
        height: primaryImgSize,
        padding: '8px',
      }}
      alt={primaryImgName}
      src={primaryImgUrl}
      width={primaryImgSize}
      height={primaryImgSize}
    />
    {secondaryImgName && secondaryImgUrl ? (
      <div
        className={Styles.teamImageContainer}
        style={{
          position: 'absolute',
          top: secondaryImgSize,
          left: secondaryImgSize * 1.4,
          width: secondaryImgSize,
          height: secondaryImgSize,
        }}
      >
        <Image
          fallback="/research/images/personPlaceholder.svg"
          style={{
            width: secondaryImgSize,
            height: secondaryImgSize,
            padding: '4px',
          }}
          alt={secondaryImgName}
          src={secondaryImgUrl}
          width={secondaryImgSize}
          height={secondaryImgSize}
        />
      </div>
    ) : null}
  </div>
);

export default TeamPlayerAvatar;
