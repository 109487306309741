import tw, { styled } from 'twin.macro';

type DividerProps = {
  color?: 'light' | 'dark',
};

const StyledDivider = styled.hr(({ color }) => [
  tw`m-0 h-px border-0`,
  color === 'light' && tw`bg-grey-l4`,
  color === 'dark' && tw`bg-grey-l2`,
]);

const Divider = ({ color = 'dark' }: DividerProps) => (
  <StyledDivider data-testid="divider" color={color} />
);

export default Divider;
