import { useQuery } from '@tanstack/react-query';
import { ShortFormSearchInput } from '@/graphql/gql/graphql';
import type { News } from '@/types/playerNewsHome';
import { filterEmpty } from '..';
import { getPlayerNewsHome } from '../api';

const usePlayerNewsHome = ({ sport, limit }: ShortFormSearchInput) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['playerNewsHome', sport, limit],
    queryFn: () => getPlayerNewsHome({ sport, limit }),
    select: (response) => {
      const shortForms = response.getShortForms?.shortForms ?? [];
      const news = shortForms.map((el) => el?.entity) as unknown as News[];
      const filtered = filterEmpty(news);
      return filtered;
    },
  });

  return { data, isLoading, isError };
};

export default usePlayerNewsHome;
