import tw, { styled } from 'twin.macro';
import PlayerNewsMaybeLink from '@/components/playerNews/PlayerNewsMaybeLink';
import TeamPlayerAvatar from '@/components/teamPlayerAvatar';
import { ShortForm, ContentSportsEnum } from '@/graphql/gql/graphql';
import { getProfileImgUrls } from '@/utils/playerNewsHome';
import type { News } from '@/types/playerNewsHome';
import PlayerNewsPublish from './PlayerNewsPublish';

type PlayerNewsCardProps = {
  news: News,
  sportName: ContentSportsEnum,
};

const Title = styled.h2(() => [
  tw`text-sm font-bold leading-5 text-grey-d4 line-clamp-2 grow-0`,
]);

const PlayerNewsCard = ({ news, sportName }: PlayerNewsCardProps) => {
  const { publishedDate, attribution, primaryRef } = news;
  const { name: sourceName, link: sourceLink } = attribution ?? {};
  const {
    primaryImgName,
    primaryImgUrl,
    secondaryImgName,
    secondaryImgUrl,
  } = getProfileImgUrls(primaryRef, sportName);

  return (
    <div
      data-testid="player-news-card"
      tw="flex flex-nowrap justify-between gap-6 py-4 pl-4 pr-6"
    >
      <div tw="grow-0">
        <Title>
          <PlayerNewsMaybeLink news={news as unknown as ShortForm} />
        </Title>
        <PlayerNewsPublish
          sourceName={sourceName ?? ''}
          sourceLink={sourceLink ?? ''}
          date={publishedDate ?? ''}
          sportName={sportName}
        />
      </div>
      <div tw="h-[3.75rem] w-[3.75rem] min-w-[3.75rem]">
        <TeamPlayerAvatar
          primaryImgName={primaryImgName}
          primaryImgUrl={primaryImgUrl}
          primaryImgSize={60}
          secondaryImgName={secondaryImgName}
          secondaryImgUrl={secondaryImgUrl}
          secondaryImgSize={30}
        />
      </div>
    </div>
  );
};

export default PlayerNewsCard;
