import { ContentSportsEnum } from '@/graphql/gql/graphql';
import { PRIMARY_REF_TYPE } from '@/constants/playerNews';
import type { PrimaryRef } from '@/types/playerNewsHome';

export const isPrimaryRefPlayer = (primaryRef: PrimaryRef) => (
  primaryRef.type === PRIMARY_REF_TYPE.PLAYER
);

export const getProfileImgUrls = (
  primaryRef: PrimaryRef,
  sportName: ContentSportsEnum,
) => {
  // Primary reference can be a player or a team. Begin with these default values
  let primaryImgUrl = primaryRef.image?.url ?? '/research/images/personPlaceholder.svg';
  const primaryImgName = primaryRef.name ?? '';
  const secondaryImgUrl = primaryRef.team?.image?.url ?? '';
  const secondaryImgName = primaryRef.team?.name ?? '';

  // If primary reference is a player, use player image and team logo
  if (isPrimaryRefPlayer(primaryRef)) {
    // If NFL, use jersey image instead of player image
    if (sportName === ContentSportsEnum.Nfl) {
      primaryImgUrl = primaryRef.team?.teamJerseyImage?.url ?? '/research/images/personPlaceholder.svg';
    }
  }

  return {
    primaryImgName,
    primaryImgUrl,
    secondaryImgName,
    secondaryImgUrl,
  };
};
