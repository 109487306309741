import 'twin.macro';
import { ContentAuthor, Maybe } from '@/graphql/gql/graphql';
import { getTwitterAccount } from '@/utils/author';
import { relativeDate } from '@/utils/date';
import Link from '@/components/Link';

type ArticlePublishInfoProps = {
  author: ContentAuthor,
  date?: Maybe<string>,
};

const ArticleCardPublish = ({ author, date }: ArticlePublishInfoProps) => {
  const { socialMedia } = author;
  const twitterAccount = socialMedia && getTwitterAccount(socialMedia);

  return (
    <div tw="text-xs font-normal leading-4">
      {twitterAccount ? (
        <Link
          next={false}
          href={twitterAccount.url}
          tw="font-normal text-blue-d1"
        >
          {author.name}
        </Link>
      ) : (
        <span tw="text-grey-d4">{author.name}</span>
      )}
      <span tw="pl-1">&#8226;</span>
      {date && (
      <time dateTime={date} tw="pl-1" suppressHydrationWarning>
        {relativeDate(date)}
      </time>
      )}
    </div>
  );
};

export default ArticleCardPublish;
