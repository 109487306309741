import { useCallback } from 'react';
import tw, { styled } from 'twin.macro';
import Link from 'next/link';
import Image from '@/components/Image';
import Tag from '@/components/Tag';
import { ARTICLE_LINK_CLICKED } from '@/constants/amplitude';
import { useAmplitude } from '@/utils/hooks/useAmplitude';
import { Article } from '@/graphql/gql/graphql';
import ArticleCardPublish from './ArticleCardPublish';

type ArticleCardProps = Pick<Article, 'author' | 'title'> & {
  image?: string,
  link: string,
  origin: string,
  publishedOn?: Article['firstPublishedAt'],
  showImage?: boolean,
  sportName: string,
  sportSlug?: string,
};

const ImageContainer = styled.div(() => [
  tw`relative ml-3 h-[77px] w-[133px] min-w-[133px]`,
]);

const Title = styled.h2(() => [
  tw`text-sm font-bold leading-5 text-grey-d4 line-clamp-3`,
]);

const ArticleCard = ({
  author,
  image = '/research/images/fd_logo.webp',
  link,
  origin,
  publishedOn,
  showImage = true,
  sportName,
  sportSlug,
  title,
}: ArticleCardProps) => {
  const { logAmplitudeEvent } = useAmplitude();
  const onClick = useCallback(() => logAmplitudeEvent(
    ARTICLE_LINK_CLICKED,
    {
      'Link Text': title,
      'Link URL': link,
      Module: origin,
      Competition: sportName,
    },
  ), [link, logAmplitudeEvent, origin, sportName, title]);

  return (
    <div tw="flex h-full w-full flex-nowrap items-center justify-between px-4 py-2">
      <div tw="flex flex-col justify-center">
        {sportSlug && (
          <Tag color="grey" label={sportName} href={`/${sportSlug}`} />
        )}
        <Title>
          <Link href={link} prefetch={false} onClick={onClick}>
            {title}
          </Link>
        </Title>
        <ArticleCardPublish author={author} date={publishedOn} />
      </div>
      {showImage && (
        <ImageContainer>
          <Link href={link} prefetch={false} onClick={onClick}>
            <Image
              alt={title}
              fill
              src={image}
              sizes="180px"
              tw="rounded object-cover"
            />
          </Link>
        </ImageContainer>
      )}
    </div>
  );
};

export default ArticleCard;
