import { theme } from 'twin.macro';
import styled from 'styled-components';
import { SVGProps } from '@/types/svg';
import Svg from './Svg';
import { colorGreyD4 } from '../../styles/manualVars';

const Icon = styled(Svg) <SVGProps>` 
  display: inline;
  fill: ${({ color = theme`colors.grey-d4` }) => color};
  margin: ${({ margin = '0 0 0 0' }) => margin};
`;

const East = ({ color, margin }: SVGProps) => (
  <Icon data-testid="east-svg" color={color} width="20" height="20" margin={margin}>
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0249 5.69165L17.0832 10.75L12.0249 15.8083" stroke={colorGreyD4} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.91675 10.75H16.9417" stroke={colorGreyD4} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </Icon>
);

export default East;
