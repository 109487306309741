import { SVGProps } from '@/types/svg';
import Svg from './Svg';
import { colorBlueSvg } from '../../styles/manualVars';

const ArrowRight = ({ width = '5', height = '10', fill = colorBlueSvg }: SVGProps) => (
  <Svg data-testid="arrow-right" width={width} height={height}>
    <path xmlns="http://www.w3.org/2000/svg" d="M0.14625 0.646501C-0.04875 0.841501 -0.04875 1.1585 0.14625 1.3535L3.79225 4.9995L0.14625 8.6455C-0.04875 8.8405 -0.04875 9.1575 0.14625 9.3525C0.34125 9.5475 0.65825 9.5475 0.85325 9.3525L4.85325 5.3525C4.95125 5.2545 4.99925 5.1265 4.99925 4.9985C4.99925 4.8705 4.95025 4.7425 4.85325 4.6445L0.85325 0.644501C0.65825 0.451501 0.34225 0.451501 0.14625 0.646501Z" fill={fill} />
  </Svg>
);
export default ArrowRight;
