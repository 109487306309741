import 'twin.macro';
import { Article, Maybe, Tool } from '@/graphql/gql/graphql';
import ArticleCard from '@/components/ArticleCard';
import Divider from '@/components/Divider';
import SpotlightArticle from '@/components/SpotlightArticle';
import ToolComponent from '@/components/home/hero/Tool';

type Template3ColumnProps = {
  col1Article: Article,
  col2Articles: Article[],
  col3Articles: Article[],
  sportName: string,
  tool?: Maybe<Tool>,
};

const Template3Column = ({
  col1Article,
  col2Articles = [],
  col3Articles = [],
  sportName,
  tool,
}: Template3ColumnProps) => {
  const {
    title,
    description,
    slug,
    firstPublishedAt,
    hero,
    author,
  } = col1Article;

  return (
    <div tw="grid grid-cols-1 lg:grid-cols-2 lg:gap-4 xl:grid-cols-3">
      <SpotlightArticle
        author={author}
        fullwidthMobileImage={false}
        image={hero?.imageUrl?.url}
        link={`/${slug}`}
        origin="home_sport_articles"
        publishedOn={firstPublishedAt}
        snippet={description}
        sportName={sportName}
        title={title}
      />
      <div tw="col-span-1 grid">
        {col2Articles.map((article, idx) => (
          <div key={article.id}>
            <ArticleCard
              author={article.author}
              image={article.hero?.imageUrl?.url}
              link={`/${article.slug}`}
              origin="home_sport_articles"
              publishedOn={article.firstPublishedAt}
              sportName={sportName}
              title={article.title}
            />
            {idx < col2Articles.length - 1 && <Divider color="light" />}
          </div>
        ))}
      </div>
      <div tw="col-span-1 grid lg:col-span-3 xl:col-span-1">
        <div tw="xl:hidden">
          <Divider color="light" />
        </div>
        {tool ? <ToolComponent origin="home_sport_tool" condensed {...tool} /> : undefined}
        {col3Articles.map((article, idx) => (
          <div key={article.id}>
            <ArticleCard
              author={article.author}
              image={article.hero?.imageUrl?.url}
              link={`/${article.slug}`}
              origin="home_sport_articles"
              publishedOn={article.firstPublishedAt}
              sportName={sportName}
              title={article.title}
            />
            {idx < col3Articles.length - 1 && <Divider color="light" />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Template3Column;
