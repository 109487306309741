import 'twin.macro';
import { ContentSportsEnum, Sport } from '@/graphql/gql/graphql';
import PlayerNewsHeader from './PlayerNewsHeader';
import PlayerNewsContainer from './PlayerNewsContainer';

type PlayerNewsProps = {
  sportName: ContentSportsEnum,
  sportSlug: Sport['slug'],
};

const PlayerNews = ({ sportName, sportSlug }: PlayerNewsProps) => (
  <div>
    <PlayerNewsHeader sportName={sportName} sportSlug={sportSlug} />
    <PlayerNewsContainer sportName={sportName} />
  </div>
);

export default PlayerNews;
