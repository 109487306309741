import React from 'react';
import 'twin.macro';
import Link from 'next/link';
import { Sport } from '@/graphql/gql/graphql';
import { Button } from '@fanduel/og-fe-library';
import Image from '@/components/Image';

type LeagueSectionHeaderProps = {
  sportName: Sport['name'],
  sportSlug: Sport['slug'],
  sportLogo?: Sport['logo'],
  title?: string,
};

const FeaturedSportHeader = ({
  sportName,
  sportSlug,
  sportLogo,
  title,
}: LeagueSectionHeaderProps) => {
  const buttonText = `View all ${sportName} news`;

  return (
    <div tw="flex flex-wrap justify-between py-4 md:px-4 md:pb-0">
      <h3 tw="flex items-center gap-2 pl-4 text-base font-bold leading-5 text-grey-d4 md:pl-2 md:text-2xl">
        {sportLogo?.url && (
          <Image
            style={{
              display: 'inline',
              height: '30px',
              marginRight: '.5rem',
              width: 'auto',
            }}
            width={30}
            height={30}
            alt={sportName}
            src={sportLogo?.url}
          />
        )}
        {title ?? sportName}
      </h3>
      <Link href={`/${sportSlug}`} tw="flex items-center justify-center text-blue-d1">
        <Button
          ariaLabelText={buttonText}
          buttonText={buttonText}
          buttonStyle="Naked"
          rightIconName="arrows.right"
          iconColor="colorBaseBlueDarken2"
          tw="flex flex-nowrap text-inherit"
        />
      </Link>
    </div>
  );
};

export default FeaturedSportHeader;
