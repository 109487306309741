import tw, { styled } from 'twin.macro';
import { useMemo } from 'react';
import { Article, ContentSportsEnum, SelectedSport } from '@/graphql/gql/graphql';
import Template3Column from '@/components/templates/sport/Template3Column';
import FeaturedSportHeader from './FeaturedSportHeader';
import PlayerNews from '../playerNews/PlayerNews';

type FeaturedSportProps = {
  selectedSport: SelectedSport,
  title?: string,
};

const CardContent = styled.div(() => [
  tw`p-0 flex flex-col`,
]);

const FeaturedSport = ({ selectedSport, title }: FeaturedSportProps) => {
  const { sport, featuredArticles, tool } = selectedSport ?? {};
  const [spotlightArticle, ...remainingArticles] = (featuredArticles as Article[]) ?? [];
  const [col2Articles, col3Articles] = useMemo(() => {
    const cutOff = tool ? 9 : 10;
    return [
      remainingArticles?.slice(0, 5),
      remainingArticles?.slice(5, cutOff),
    ];
  }, [remainingArticles, tool]);

  if (!featuredArticles) {
    return null;
  }

  const {
    logo,
    name: sportName,
    slug: sportSlug,
    hasPlayerUpdates,
  } = sport;

  return (
    <div>
      <FeaturedSportHeader
        sportName={sportName}
        sportSlug={sportSlug}
        sportLogo={logo}
        title={title}
      />
      <CardContent>
        <div tw="md:p-4">
          <Template3Column
            col1Article={spotlightArticle}
            col2Articles={col2Articles}
            col3Articles={col3Articles}
            sportName={sportName}
            tool={tool}
          />
        </div>
        {
        // temporary removal until we add more player news content
        (hasPlayerUpdates && false)
          && <PlayerNews sportName={sportName as ContentSportsEnum} sportSlug={sportSlug} />
}
      </CardContent>
    </div>
  );
};

export default FeaturedSport;
